<!-- 政策库-->
<template>
  <div>
    <div style="width: 100%">
      <div class="zcjsq_banner"></div>
    </div>
    <div class="def-container find-list-container">
      <!-- <div class="findList" ref="findList">
        <div class="find-tabs">
          <div class="search_table_con">
            <div class="flex-row-between chosenDiv"></div>
            <div class="seperation"></div>
            <div
              v-show="isShowTable"
              class="flex-row chosenDiv bgColor"
              style="font-size: 18px"
            >
              <span>计算结果：</span>
              <div style="color: #ff9570; margin-left: 50px">
                共<span class="numStyle">{{ policyNum }}</span
                >份惠企政策，<span class="numStyle">{{ projectNumTotal }}</span
                >个项目，可获得资助资金总额最高为<span
                  v-if="money"
                  class="numStyle"
                  >: {{ money }}万元</span
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <el-row type="flex" style="width: 100%;margin-top:30px">
        <el-col :span="12">
          <p style="color: #999;font-weight: 600;font-size: 22px;line-height:76px">
            已为您计算出合适的政策<span class="numStyle">{{ policyNum }}</span
            >项，预计可获取奖励资金<span v-if="money" class="numStyle">{{
              money
            }}</span
            >万元
          </p>
          <FilterGroup>
            <!-- <div style="width:100%;margin-top: 24px;">
              <el-input
                placeholder="请输入您要查询的政策关键字"
                style="width: 500px;margin-left: 20%;max-width: 40%"
                v-model="searchdata"
              />
              <el-button class="computeBtn" round @click="getsearchList" style="margin-top:5px">开始计算</el-button>
            </div> -->
            <FilterItem label="已选择条件:" :needAll="false">
              <div class="filter-params-inner">
                <div class="filterItem-params-left">
                  <span
                    style="
                      color: #000 !important;
                      font-size: 16px;
                      margin-left: 40px;
                      margin-left: 20px;
                    "
                    v-for="(item, index) in cpu_paramsList"
                    :key="index"
                  >
                    {{ item.label }}
                  </span>
                  <span
                    v-if="!cpu_paramsList.length"
                    style="color:#000 !important; margin-left: 20px"
                    >无</span
                  >
                  <el-button
                    v-else
                    style="
                      color: #fff;
                      background-color: #22b7fd;
                      padding: 4px 12px;
                    "
                    @click="cleanParams"
                    >清空</el-button
                  >
                </div>
              </div>
            </FilterItem>
          </FilterGroup>
        </el-col>
        <el-col :span="2" :offset="1">
          <el-button round class="computeBtn1" style="border-radius: 24px;width: 180px;" @click="openKey"
            >重新计算</el-button
          >
        </el-col>
      </el-row>
      <div v-show="isShowTable" class="wrap-content">
        <span
          style="
            font-size: 24px;
            font-weight: bold;
            font-style: italic;
            padding: 10px;
          "
          >计算</span
        ><span style="font-size: 24px; font-weight: bold; color: #1371e6"
          >结果</span
        >
        <el-input
          placeholder="请输入您要查询的政策关键字"
          style="width: 500px; margin: 10px; max-width: 40%"
          v-model="searchdata"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            style="background-color: transparent"
            @click="getsearchList"
          ></el-button>
        </el-input>
        <el-table
          class="table-policy"
          v-loading="dataLoading"
          element-loading-text="正在计算中。。。"
          element-loading-spinner="el-icon-loading"
          :data="policyInfo.data"
          :show-header="false"
          size="medium"
          row-class-name="policy-item"
          :row-style="{ height: '140px' }"
        >
          <el-table-column
            show-overflow-tooltip
            prop="name"
            label="政策名称"
            width=""
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div style="font-size: 20px; font-weight: bold; padding: 5px">
                <span>{{ scope.row.name }}</span>
              </div>
              <div style="padding: 5px">
                <i class="el-icon-info"></i>
                <span>主管部门：{{ scope.row.fbjg }}</span
                >&nbsp;
                <span>支持方式：{{ scope.row.zcfs }}</span>
              </div>
              <div style="padding: 5px">
                <i class="el-icon-time"></i>
                <span
                  >申报时间：{{ scope.row.starttime }} 至
                  {{ scope.row.endtime }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="policyItemsNum"
            label="扶持资金"
            show-overflow-tooltip
            header-align="left"
            width="200px"
          >
            <template slot-scope="scope">
              <div>
                <span
                  style="
                    font-size: 30px;
                    font-weight: bold;
                    padding: 10px;
                    color: #f28d21;
                  "
                  >{{ scope.row.policyItemsNum }}</span
                >
                万元
              </div>
              <div style="padding: 10px 25px">
                <span>最高奖励</span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="projectNum"
            label="扶持项目数"
            align="center"
            width="120px"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="projectShow(scope.row.id)">{{
                scope.row.projectNum
              }}</el-button>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="operation"
            label="操作"
            align="center"
            width="300px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.endday != 'past'">
                距离申报截止时间<span
                  style="
                    font-size: 30px;
                    font-weight: bold;
                    padding: 10px;
                    color: #f28d21;
                  "
                  >{{ scope.row.endday }}</span
                >天
              </div>
              <div v-if="scope.row.endday == 'past'">申报结束</div>
              <div>
                <el-button type="text" @click="toPolicyGuide(scope.row)"
                  >查看政策详情</el-button
                >
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click="projectShow(scope.row.id)"
                  >查看项目</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-show="isShowTable && pageInfo.total > 0" class="pagination">
        <el-pagination
          background
          layout="total,prev,pager,next,jumper"
          :current-page="pageInfo.page"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!--登录提示弹窗-->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>需要登录才能收藏，是否登录？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="gotoLogin">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title=""
      :visible.sync="keyVisible"
      :close-on-click-modalb="false"
      :close-on-press-escape="false"
      width="70%"
    >
      <div style="font-weight: 600; font-size: 30px; text-align: center">
        <span>企业政策计算器</span>
      </div>
      <div style="font-size: 16px; text-align: center">
        <span>请选择您的条件，我将为您计算出适合您的政策</span>
      </div>
      <FilterGroup @change="changeSearch">
        <FilterCheckBox
          v-model="searchInfo.data.dq"
          label="政策级别"
          :list="getDictList('policy_level')"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBox>
        <FilterCheckBox
          v-model="searchInfo.data.qysx"
          label="企业属性"
          :list="getDictList('enterprise_attributes').filter(item=>{
            return item.label!='不限'
          })"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBox>
        <!-- <FilterCheckBox
              v-model="searchInfo.data.qygm"
              label="企业规模"
              :list="getDictList('enterprise_size')"
              valueKey="key"
              :needAll="true"
              :type="1"
              fold
            ></FilterCheckBox>
            <FilterCheckBox
              v-model="searchInfo.data.growth"
              label="成长周期"
              :list="getDictList('growth_cycle')"
              valueKey="key"
              :needAll="true"
              :type="1"
              fold
            ></FilterCheckBox> -->
        <FilterCheckBox
          v-model="searchInfo.data.hyle"
          label="行业类别"
          :list="getDictList('sys_category').filter(item=>{
            return item.label!='不限'
          })"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBox>
      </FilterGroup>
      <div style="text-align: center">
        <el-button class="computeBtn1" round @click="getsearchList"
          >开始计算</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="项目"
      :visible.sync="projectVisible"
      width="80%"
      :modal-append-to-body="false"
    >
      <div style="width: 100%; padding: 20px">
        <el-input
          placeholder="请输入项目代码或项目名称进行查询"
          style="width: 40%; margin-left: 72px"
          v-model="serachProject"
        />
        <el-button
          style="color: #fff; background-color: #22b7fd; margin-left: -2px"
          @click="projectShow(policyId)"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="projectShowList"
        size="medium"
        :header-row-style="headerRowStyle"
        :header-cell-style="headerRowStyle"
      >
        <el-table-column
          property="itemBaseCode"
          label="项目编码"
        ></el-table-column>
        <el-table-column
          property="name"
          label="项目名称"
          width="600px"
        ></el-table-column>
        <el-table-column property="deptName" label="主管部门"></el-table-column>
        <el-table-column property="startTime" label="申报开始时间">
          <template slot-scope="scope">
            <span>{{ scope.row.startTime.substr(0, 10) }}</span>
          </template>
        </el-table-column>
        <el-table-column property="endTime" label="申报结束时间">
          <template slot-scope="scope">
            <span>{{ scope.row.endTime.substr(0, 10) }}</span>
          </template>
        </el-table-column>
        <el-table-column property="zzxe" label="最高奖励（万元）">
        </el-table-column>
        <!-- <el-table-column prop="operation" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="toProject(scope.row)">申报</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total,prev,pager,next,jumper"
          :current-page="pageInfoProject.pageNum"
          :page-size.sync="pageInfoProject.pageSize"
          :total="pageInfoProject.total"
          @current-change="handleProjectCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { FormInput } from '@/components/form/index.js'
// import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片
// import SectionHeader from "@/components/sectionHeader";
// import TjIcon from '@/components/tjIcon/TjIcon';
import {
  FilterGroup,
  // FilterRadio,
  FilterCheckBox,
  FilterItem,
} from "./components/filter/index.js";
import util from "@/utils/util.js";
// import NoDataView from "@/components/nodata-view";

export default {
  components: {
    // FormInput,
    // DeclareCell,
    // SectionHeader,
    // TjIcon,
    // FilterRadio,
    FilterGroup,
    FilterCheckBox,
    FilterItem,
    // NoDataView,
  },
  data() {
    return {
      radio: "",
      keyVisible: true, //关键字弹框
      dialogVisible: false, //登录提示弹窗
      projectVisible: false, //项目列表展示
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      isShowTable: false,
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
          dq: "", //地区
          qysx: "", //企业属性
          qygm: "", //企业规模
          growth: "", //成长周期
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        areaList: [], //地区列表
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 7,
        total: 0,
      },
      // 项目分页
      pageInfoProject: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      searchdata: "", //搜索输入数据
      searchDataHyle: "", //搜索行业类别需要的数据
      searchDataZcfl: "", //搜索政策分类需要的数据
      searchDataDq: "", //搜索地区需要的数据
      searchDataDept: "", //搜索部门需要的数据
      searchDataQysx: "", //搜索企业属性需要的数据
      searchDataQygm: "", //搜索企业规模需要的数据
      searchDataGrowth: "", //搜索成长周期需要的数据

      policyInfo: {
        data: [],
      },
      projectShowList: [], //项目数组
      serachProject: "", //项目搜索数据
      policyId: "", //政策id
      policyNum: "", //政策数量
      projectNumTotal: "", //项目数量
      ksbxm: "", //可申报项目
      wsbxm: "", //未申报项目
      money: "", //资助总额
      headerRowStyle: {
        color: " #22b7fd",
        backgroundColor: "#f5f5f5",
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    /**
     * @description: 暂无数据状态位
     * @return {String}
     */
    cpu_noData() {
      if (!this.dataLoading && !this.policyInfo.data.length) {
        return "noData";
      }
      return "";
    },
    /**
     * @description: 产业类别列表
     */
    cpu_cylbList() {
      let list = JSON.parse(JSON.stringify(this.getDictList("sys_enter_type")));
      if (!!list.length) {
        list[0].key = "";
        list[0].label = "不限";
      }

      return list;
    },
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    cpu_paramsList() {
      let list = [];

      // 政策级别
      if (this.searchDataDq) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataDq !== "") {
          arrData = this.searchDataDq.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("policy_level").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "dq",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 企业属性
      if (this.searchDataQysx) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataQysx !== "") {
          arrData = this.searchDataQysx.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("enterprise_attributes").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "qysx",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 行业类别
      if (this.searchDataHyle) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataHyle !== "") {
          arrData = this.searchDataHyle.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("sys_category").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "hyle",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 政策分类
      if (this.searchDataZcfl) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataZcfl !== "") {
          arrData = this.searchDataZcfl.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("sq_policy_xmlb").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "zcfl",
            value: listvalue.join(","),
            label: listlabel.join(","),
          });
        }
      }

      // 企业规模
      if (this.searchDataQygm) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataQygm !== "") {
          arrData = this.searchDataQygm.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("enterprise_size").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "qygm",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 成长周期
      if (this.searchDataGrowth) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataGrowth !== "") {
          arrData = this.searchDataGrowth.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("growth_cycle").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "growth",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }

      // 政策级别是单选的时候
      // if (this.searchDataDq) {

      //   list.push({
      //     type: "dq",
      //     value: this.searchDataDq,
      //     label: this.getDictList("policy_level").find(
      //       (cv) => cv.key === this.searchDataDq
      //     )?.label,
      //   });
      // }
      if (this.searchDataDept) {
        list.push({
          type: "dept",
          value: this.searchDataDept,
          label: this.searchInfo.deptList.find(
            (cv) => cv.deptid === this.searchDataDept
          )?.deptname,
        });
      }
      // 去掉第一个逗号
      list.filter((item) => {
        if (item.label[0] == "，") {
          item.label = item.label.substring(1);
        }
      });
      return list;
    },
    /**
     * @description: 表格 办理对象 显示文本
     * @param {String} bldx 办理对象
     * @return {String}
     */
    cpu_blzcText() {
      return function (bldx) {
        if (!bldx) return "";

        return this.getDictLabel("permit_sertype", bldx);
      };
    },
    /**
     * @description: 表格 企业注册类型 显示文本
     * @param {String} qyzclx 企业注册类型
     * @return {String}
     */
    cpu_qyzclxText() {
      return function (qyzclx) {
        if (!qyzclx) return "";

        let temp_qyzclx = qyzclx
          .split(",")
          .map((cv) => this.getDictLabel("sys_enterprise_registertype", cv))
          .join(",");
        return temp_qyzclx;
      };
    },
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
    /**
     * @description: 荣誉信息/资质认证要求 的显示文本
     * @param {String} ryxx 荣誉信息
     * @param {String} zzrzyq 资质认证要求
     * @return {String}
     */
    cpu_ryzzText() {
      return function (ryxx, zzrzyq) {
        if (ryxx && zzrzyq) {
          return `${ryxx} / ${zzrzyq}`;
        } else if (!ryxx && !zzrzyq) {
          return "";
        }
        return ryxx || zzrzyq;
      };
    },
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
  },
  methods: {
    openKey() {
      this.keyVisible = true;
    },
    /**
     * @description 清空已选搜索项
     */
    cleanParams() {
      this.searchDataHyle = "";
      this.searchDataZcfl = "";
      this.searchDataDq = "";
      this.searchDataDept = "";
      this.searchDataQysx = "";
      this.searchDataQygm = "";
      this.searchDataGrowth = "";
      this.showList = [];
      this.pageInfo.total = 0;
      // this.getsearchList(1, this.pageInfo.size);
    },
    /**
     * @description: 删除已选搜索项
     * @param {Object} tag
     */
    closeParams(tag) {
      switch (tag.type) {
        case "temp_amount":
          this.searchInfo.data.temp_amount = "";
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          this.searchInfo.data[tag.type] = "";
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getsearchList(1, this.pageInfo.size);
    },
    /**
     * @description 合并表格行列
     * @param {Object} row 行
     * @param {Object} column 列
     * @param {Number} rowIndex 行下标
     * @param {Number} columnIndex 行下标
     */
    mergeTable({ row, column, rowIndex, columnIndex }) {
      // console.log('mergeTable:', row, column, rowIndex, columnIndex)
      if (column.type === "index" || column.property === "name") {
        // 是否与上一行同政策，是 则不占单元格
        if (0 < rowIndex && row.name === this.showList[rowIndex - 1].name) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
        // 是否与下一行同政策，是 则占2行单元格
        let rowNum = 1;
        if (rowIndex < this.showList.length - 1) {
          for (let i = rowIndex + 1; i < this.showList.length; i++, rowNum++) {
            if (row.name !== this.showList[i].name) break;
          }
          return {
            rowspan: rowNum,
            colspan: 1,
          };
        }
      }
    },
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.userInfo) {
        let identify = this.userInfo.tyshxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            // console.log('getCollectList:', res)
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    //比对收藏列表,判断是否收藏
    isItemCollect(item) {
      let list = this.collectList;

      for (let i = 0; i < list.length; i++) {
        let collectItem = list[i];
        if (item.id == collectItem.collectionId) {
          // console.log(item.name + ":已收藏");
          return collectItem;
        }
      }
      return null;
    },
    /**
     * @description: 切换页码
     * @param {Number} page 页码
     */
    handleCurrentChange(page) {
      // this.showList = [];
      this.policyInfo.data = [];
      this.pageInfo.page = page;
      if (this.searchdata) {
        //搜索框查询
        this.searchPolicy();
      } else {
        this.getsearchList(page, this.pageInfo.size, true); //筛选查询
      }
    },
    handleProjectCurrentChange(page) {
      this.projectShowList = [];
      this.pageInfoProject.pageNum = page;
      this.projectShow(this.policyId);
    },
    /**
     * 监听declareCeil子组件点击收藏事件
     */
    clickIsFollow(item) {
      //判断是否登录
      if (this.$store.getters.getUserInfo) {
        //登录
      } else {
        //未登录
        this.dialogVisible = true;
      }
    },
    /*
     * 监听DeclareCeil子组件点击获取详情事件
     */
    clickCell(item) {
      // console.log("选择的是:", item);
      // alert("转跳")
      this.$store.commit("updateCurrentGuideId", item.id);
      if (this.activeTab == 0) {
        this.$router.push({
          path: "/guideSuqiu",
          query: {
            name: item.name,
            itemType: item.itemtype,
            isAdded: item.isAdded,
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * 根据专项名称关键字搜索
     */
    searchByKeyword(data) {
      this.searchInfo.data.name = data;
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "行业类别":
          this.searchDataHyle = value;
          break;
        // case "政策分类":
        //   this.searchDataZcfl = value;
        //   break;
        case "政策级别":
          // value = value.substring(1);
          this.searchDataDq = value;
          break;
        // case "主管部门":
        //   this.searchDataDept = value;
        //   break;
        case "企业属性":
          this.searchDataQysx = value;
          break;
        case "企业规模":
          this.searchDataQygm = value;
          break;
        case "成长周期":
          this.searchDataGrowth = value;
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      // this.showList = [];
      this.policyInfo.data = [];
      this.pageInfo.total = 0;
      // this.getsearchList(1, 10);
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    /**
     * @description: 查询政策列表
     */
    getAreaList() {
      let url = "/dev-api/cfginfo/region/cascaderTreeList";
      let params = {};
      let that = this;
      this.$httpApi
        .get(url, params)
        .then((r) => {
          let getdata = that.getTreeFlat(r.rows);
          this.searchInfo.areaList = getdata;
        })
        .catch((e) => {});
    },
    //给treeData扁平化处理拿对应数据
    getTreeFlat(arr, parent = false) {
      const dataList = [];
      const generateList = (data, deepcount) => {
        let num = deepcount;
        if (num < 2) {
          for (let i = 0; i < data.length; i++) {
            const { id, value, label, children } = data[i];
            if (!parent) dataList.push({ id, value, label });
            if (Array.isArray(children) && children.length > 0) {
              if (parent) dataList.push({ id, value, label });
              num++;
              generateList(children, num);
            }
          }
        }
      };
      generateList(arr, 0);
      return dataList;
    },
    DateDiff(sDate1, sDate2) {
      //sDate1和sDate2是yyyy-MM-dd格式
      if (sDate1 > sDate2) {
        return "past";
      }
      var aDate, oDate1, oDate2, iDays;
      aDate = sDate1.split("-");
      oDate1 = new Date(aDate[1] + "-" + aDate[2] + "-" + aDate[0]); //转换为yyyy-MM-dd格式
      aDate = sDate2.split("-");
      oDate2 = new Date(aDate[1] + "-" + aDate[2] + "-" + aDate[0]);
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数

      return iDays; //返回相差天数
    },
    // 查询政策
    getsearchList(page, size) {
      this.isShowTable = true;
      this.dataLoading = true;
      this.keyVisible = false;
      let that = this;
      let url = "/dev-api/business/sq-apply/policyLibraryMatching";
      let flag = false;
      setTimeout(function () {
        flag = true;
      }, 4000);
      that.policyId = "";
      let params = {
        areaid: that.searchDataDq, //区划id
        sysCategory: that.searchDataHyle, //行业类别
        enterpriseAttributes: that.searchDataQysx, //企业属性
        enterpriseSize: that.searchDataQygm, //企业规模
        growthCycle: that.searchDataGrowth, //成长周期
        pageNums: that.pageInfo.page,
        pageSizes: that.pageInfo.size,
        status: "1",
        search: that.searchdata,
      };
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isChoose = false;
      } else {
        params.isChoose = true;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.policyInfo.data = r.pageInfo.list;
          that.policyInfo.data.forEach((item) => {
            item.star = 5;
            item.endday = this.DateDiff(util.getNowDate("day"), item.endtime);
          });
          let time = setInterval(() => {
            if (flag) {
              that.pageInfo.total = r.pageInfo.total;
              that.policyNum = r.pageInfo.total;
              that.projectNumTotal = r.sqPolicyNum.projectNumTotal;
              that.ksbxm = r.sqPolicyNum.ksbxm;
              that.wsbxm = r.sqPolicyNum.wsbxm;
              that.money = r.sqPolicyNum.money;
              this.dataLoading = false;
              clearInterval(time);
            }
          }, 1000);
        })
        .catch((e) => {});
    },
    toPolicyGuide(row, column) {
      /**
       * 20210419
       * 参考 政策类 点击前往政策项目详情，这边也做一个跳转
       */
      // this.$store.commit('updateCurrentGuideId', item.id)
      // const { href } = this.$router.resolve({
      //     path: '/guideSuqiu',
      //     query: {
      //         id: item.id,
      //         // name: item.name,
      //     },
      // })
      // window.open(href, '_blank')
      // // this.$router.push({
      // //     path: "/guideSuqiu",
      // //     query: {
      // //         name: item.name,
      // //         itemType: item.itemtype || item.type,
      // //         isAdded: item.isAdded || item.isread,
      // //     },
      // // });
      let that = this;
      // console.log("点击,转跳", val);
      // this.$router.push({
      //   path: "/notice/detail/" + val.id+"?type="+that.itemType
      // });
      // let { href } = that.$router.resolve({
      //   path: "/notice/policyNotice/" + row.id + "?type=" + "1",
      // });
      // window.open(href, "_blank");
      let data = JSON.stringify(row);
      sessionStorage.setItem("zcjsqData", data);
      this.$router.push({ name: "policyDetails" });
    },
    // 点击办理跳转政策/项目页面
    handleClick() {
      this.$router.push({ name: "policyDetails" });
    },
    // 根据条件查询
    searchPolicy() {
      let that = this;
      // let url = "/dev-api/business/sq-apply/squeryPerm";
      let url = "/dev-api/business/sq-apply/policyLibraryMatching";
      that.policyId = "";
      let params = {
        areaid: that.searchDataDq, //区划id
        // policyClassification: that.searchDataZcfl, //政策分类
        sysCategory: that.searchDataHyle, //行业类别
        // deptId: that.searchDataDept, //部门id
        enterpriseAttributes: that.searchDataQysx, //企业属性
        enterpriseSize: that.searchDataQygm, //企业规模
        growthCycle: that.searchDataGrowth, //成长周期
        // PAGE_CUR: page.toString(),
        // PAGE_ORDER: "",
        // PAGE_ROWS: size.toString(),
        // PAGE_TYPE: "P",
        pageNum: that.pageInfo.page,
        pageSize: that.pageInfo.size,
        status: "1",
        search: that.searchdata,
        dataLoading: false,
      };
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchdata == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isChoose = false;
      } else {
        params.isChoose = true;
      }
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isSearchFlag = true;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.policyInfo.data = r.list;
          that.policyInfo.data.forEach((item) => {
            item.star = 5;
          });
          that.pageInfo.total = r.total;
          that.policyNum = r.total;
        })
        .catch((e) => {});
    },
    // 查项目接口
    getItemList(policyid) {
      // this.getsearchList();
    },
    //根据政策id查询项目
    projectShow(policyId) {
      this.policyId = policyId;
      let url = "/dev-api/business/sq-apply/selectProjectList";
      let params = {
        policyId: policyId,
        pageSize: this.pageInfoProject.pageSize,
        pageNum: this.pageInfoProject.pageNum,
        serachProject: this.serachProject,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((r) => {
          this.projectVisible = true;
          if (r) {
            that.projectShowList = r.list;
            that.pageInfoProject.total = r.total;
            that.policyNum = r.total;
          }
        })
        .catch((e) => {});
    },
    toProject(row) {
      this.$store.commit("updateCurrentGuideId", row.id);
      const { href } = this.$router.resolve({
        path: "/guideSuqiu",
        query: {
          id: row.id,
          // name: item.name,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    this.getItemList();
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_level", //政策层级
        "enterprise_attributes", //企业属性
        "enterprise_size", //企业规模
        "growth_cycle", //成长周期
      ],
    });
    this.getDeptList();
    this.getAreaList();
    // this.getsearchList(this.pageInfo.page, this.pageInfo.size);
  },
  mounted() {},
  watch: {
    userInfo: {
      // deep: true,
      immediate: true,
      handler: function (newVal) {
        this.getCollectList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
::v-deep .filterItem-label {
  color:#606266 !important;
  font-weight: normal !important;
}
::v-deep .el-loading-spinner i {
  font-size: 60px;
  color: #3fffff;
}
::v-deep .el-loading-text {
  font-size: 20px;
  color: #3fffff;
}
.numStyle {
  color: #f7cb60;
  font-weight: 900;
  font-size: 34px;
}
.zcjsq_banner {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/compute.png") no-repeat center / cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.find-list-container {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-other {
      margin-left: 15px;
    }
  }

  .section-header {
    border-bottom: 4px solid #000;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    font-size: 24px;
    color: rgb(13, 28, 40);
    font-weight: 600;
    height: 46px;
    line-height: 46px;
  }

  .rank-section {
    padding-top: 10px;
    font-size: 13px;
  }

  .find-tabs {
    margin-top: 20px;
  }
}
.find-list-container {
  .el-input {
    input {
      background-color: #ffffff;
      border-radius: 0px !important;
    }
  }

  .el-input-group__append {
    button {
      background-color: rgb(75, 145, 233);
    }
  }

  .el-row {
    width: 360px;
    display: flex;

    .el-button {
      border: 0px;
      border-radius: 0px;
      width: 90px;

      .el-icon-bottom {
        margin-left: 15px;
      }

      &:hover {
        background-color: #2d8cf0;
      }
    }
  }
}
::v-deep .el-input__inner {
  height: 3.125rem;
}
::v-deep .searchItem-amount {
  border-top: none !important;

  .filterItem-label {
    color:#000 !important;
    // opacity: 0;
  }
  .filterItem-content {
    padding-left: 73px;

    .content-item--active {
      color: $col-theme !important;
      background: none !important;
    }
  }

  .searchItem-amount-custom {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #999999;

    .form-input {
      width: 80px;
      margin-right: 5px;
    }
  }
}
.search-params-clean {
  margin-right: 5px;
  color: $col-red;
}

.find-list-items-container {
  .item {
    margin-bottom: 10px;
    box-sizing: border-box;
  }
}
::v-deep .table-policy {
  .table-policy-header {
    th {
      color: #fff;
      background-color: $col-theme;
      border: none;
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
  .policy-item {
    font-size: 18px;
    .policyItem-hylb,
    .policyItem-qyzz,
    .policyItem-ryxxyq {
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
    .policyItem-zzxe {
      color: $col-red;
    }
    .policyItem-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .tj-icon {
        font-size: 20px;
        color: #999999;

        &:hover {
          color: #666666;
        }
      }
    }

    &:hover {
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.search_table_con {
  // width: 1200px;
  background: #fff;
  margin-top: -33px;
}
.seperation {
  // width: 1200px;
  height: 20px;
  background-color: #fff;
}
.chosenDiv {
  min-height: 60px;
  background: #fff;
  padding: 0 15px;
}
.computeBtn {
  background-color: $col-theme;
  //position: absolute;
  color: #fff;
  width: 180px;
  border: 1px solid #ff890b !important;
}
.computeBtn1 {
  background-color: #22b7fd;
  //position: absolute;
  color: #fff;
  width: 180px;
  margin-top: 20px;
  // border: 1px solid #ff890b !important;
  border: unset;
}
.bgColor {
  margin-bottom: 15px;
  background-color: #fffaf3;
}
.numCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  color: #fff;
  text-align: center;
  line-height: 20px;
  margin-left: 20px;
}
.pagination {
  margin: 30px auto;
  text-align: center;
}
.filter-params-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ::v-deep .el-tag {
    margin-right: 5px;
    color: $col-theme;

    .el-tag__close {
      color: $col-theme;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
.message-cell {
  position: relative;
  background-color: #ffffff;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  cursor: pointer;

  .policy_top {
    border-bottom: 1px solid $col-separator;
  }

  .policyItem-title {
    .title-name {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .el-rate {
      margin-left: 20px;
    }
    ::v-deep .el-rate {
      margin-right: 20px;
    }
  }
  .policyItem-info {
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -40px;

    .info-item {
      display: -webkit-flex;
      display: flex;
      margin-left: 40px;
      line-height: 3;

      .info-label {
        color: $col-theme;
      }

      .info-value {
        flex: 1;
        color: #000000;
      }
    }
    .info-ppd .info-value {
      display: -webkit-flex;
      display: flex;
      align-items: center;
    }
  }

  &:hover {
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

.message-cell + .message-cell {
  margin-top: 5px;
}
::v-deep .filter-item {
  padding: 0;
  background-color: transparent;
  font-size: 16px;
}
::v-deep .filterItem-label {
  color: #fff;
  font-weight: bold;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #22b7fd;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: $col-theme;
  }
  .filterItem-right span {
    color: $col-theme;
  }
  .filterItem-right .el-icon-caret-bottom {
    color: $col-theme !important;
  }
  .el-checkbox-button__inner:hover {
    color: $col-theme;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
}
</style>
